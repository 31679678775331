'use strict'

angular.module('cb.import-service', [])
    .factory('ImportTemplateService', ["ApiService", function (ApiService) {

        var importTemplateService = {
            getAllImportTemplates: function () {
                try {
                    var url = '/webapi/api/v1/importTemplate/listAllImportTemplates';
                    return ApiService.promiseGet(url);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },

            getImportTemplateById: function (id) {
                try {
                    var url = '/webapi/api/v1/importTemplate/getImportTemplateById/' + id;
                    return ApiService.promiseGet(url);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },

            createNewImportTemplate: function (data) {
                try {
                    var url = '/webapi/api/v1/importTemplate/createImportTemplate';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },

            editImportTemplate: function (data) {
                try {
                    var url = '/webapi/api/v1/importTemplate/editImportTemplate';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },

            createTemplateColumnForImportTemplate: function (data){
                try{
                    var url = '/webapi/api/v1/importTemplate/createTemplateColumnForImportTemplate';
                    return ApiService.promisePost(url, data);
                }
                catch(e){
                    console.error('Error!', e);
                }
            },

            deleteTemplateColumnForImportTemplate: function (id) {
                try {
                    var data = { Id: id };
                    var url = '/webapi/api/v1/importTemplate/deleteTemplateColumnForImportTemplate';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },

            getTemplatesByType: function (type) {
                try {
                    var data = { TemplateType: type };
                    var url = '/webapi/api/v1/importTemplate/getTemplatesByType';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },

            importProspects: function (data) {
                try {
                    var url = '/webapi/api/v1/importTemplate/importProspects';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },

            importLeads: function (data) {
                try {
                    var url = '/webapi/api/v1/importTemplate/importLeads';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },

            getAllLeadProviders: function () {
                try {
                    var url = '/webapi/api/v1/LeadProvider/GetAllLeadProviders';
                    return ApiService.promiseGet(url);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },

            flagPartCoverage: function (data) {
                try {
                    var url = '/webapi/api/v1/importTemplate/flagPartCoverage';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            }
        };

        return importTemplateService;
    }]);